<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/res">系统资源</BreadcrumbItem>
        <BreadcrumbItem>关联角色</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Spin size="large" v-if="loading" fix></Spin>
      <div style="margin-bottom: 20px;color: #0077EE;">
        <label>资源名：</label><label>{{res.resName}}</label>
        <label style="margin-left: 20px;">资源模式：</label><label>{{res.pattern}}</label>
      </div>
      <Card style="width:600px;color: #0077EE;" >
        关联角色：
        <template v-for="(item,index) in roleNameList">
          <Tag :key="index">{{item}}</Tag>
        </template>
      </Card>
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
          loading: true,
          id: null,
          res: {},
          roleNameList: [],
        }
    },
    mounted() {
      this.id = this.$route.params.id
      Promise.all([ this.getRes(), this.getData() ])
      .then(() => this.loading = false)
      .catch(() => this.loading = false)
    },
    methods: {
      getRes() {
        return request.get(`/api/sys/res/${this.id}`)
        .then((data) => this.res = data)
      },
      getData() {
        return request.get(`/api/sys/res/rel_role_name_list/${this.id}`)
        .then((data) => this.roleNameList = data ? data : [])
      },
    },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    margin-top: 20px;
  }
 ::v-deep .ivu-card{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-checkbox-group{
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
